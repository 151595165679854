import logo from "./assets/logo-v1.png";

function App() {
  return (
    <div className='w-full h-full'>
      <img src={logo} alt='logo' className='w-1/3 m-auto' />
      <h1 className='text-3xl text-center'>Coming Soon..</h1>
    </div>
  );
}

export default App;
